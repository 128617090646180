import COLORS from 'const/colors';

export const MIN_VISIBLE_ANSWER_COUNT = 2;

export const QNA_LFC_SECTION = 'QnA LFC Section';
export const QNA_SNIPPET_SECTION = 'QnA Snippet Section';
export const QNA_MODAL = 'QnA Modal';
export const QNA_DRAWER = 'QnA Drawer';
export const ANSWER_AUTO_SCROLL_BUFFER = 10;
export const ACCORDION_DWEB_SCROLL_THRESHOLD = 350;
export const ACCORDION_MWEB_SCROLL_THRESHOLD = 375;
export const DEFAULT_QNA_TYPE = 'no-type';
export const MODAL_TABS_WRAPPER_GAP = 12;
export const INITIAL_VISIBLE_QUESTIONS_COUNT = 5;

export const TAG_COLOR_OPTIONS = [
  {
    bgColor: COLORS.DREAMY_PALE.LIGHT_TONE_2,
    textColor: COLORS.TEXT.PEACHY_ORANGE_3,
  },
  {
    bgColor: '#EEF4FF',
    textColor: '#3462B4',
  },
  {
    bgColor: COLORS.HOLA_YELLOW.LIGHT_TONE_3,
    textColor: '#9F6B00',
  },
];
