export const MAX_DESCRIPTORS_DISPLAYED = 5;

export const DESCRIPTOR_RANKING_LOGIC = [
  'FREE_CANCELLATION',
  'FLEXIBLE_CANCELLATION',
  'EXTENDED_VALIDITY',
  'INSTANT_CONFIRMATION',
  'MOBILE_TICKET',
  'DURATION',
  'AUDIO_GUIDE',
  'GUIDED_TOUR',
  'TRANSFERS',
  'HOTEL_PICKUP',
  'MEALS_INCLUDED',
];

export const IGNORED_HEADINGS = {
  OPERATING_HOURS: 'operating hours DoNotTranslate',
  FREQUENCY: 'frequency DoNotTranslate',
  AUDIO_GUIDE: 'audio guide DoNotTranslate',
  POPULAR_ATTRACTIONS: 'popular attractions DoNotTranslate',
  STARTING_STOP: 'starting stop DoNotTranslate',
  CRUISE_AUDIO_GUIDE: 'Audioguide Cruises DoNotTranslate',
  CRUISE_LIVE_ENTT: 'Live Entertainment Cruises DoNotTranslate',
  CRUISE_MEALS: 'Meal Options Cruises DoNotTranslate',
  CRUISE_BOAT: 'Boat Type Cruises DoNotTranslate',
  TRAVEL_TIME: 'travel time DoNotTranslate',
};
