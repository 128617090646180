const GreenTickSvg = () => (
  <svg
    width="12"
    height="13"
    viewBox="0 0 12 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_9110_3814)">
      <path
        d="M5.76495 0.966435C5.9022 0.856021 6.0978 0.856021 6.23505 0.966435L7.40778 1.90985C7.47154 1.96114 7.55032 1.99017 7.63212 1.9925L9.12894 2.03529C9.30329 2.04027 9.45118 2.1648 9.48578 2.33575L9.78892 3.83367C9.80485 3.91241 9.84565 3.98395 9.90531 4.03776L11.0325 5.05444C11.1603 5.16971 11.1933 5.35716 11.1124 5.50909L10.3942 6.85854C10.3567 6.92901 10.3427 7.00961 10.3542 7.08861L10.5744 8.60543C10.5993 8.77665 10.5038 8.94265 10.3433 9.00722L8.94639 9.56916C8.87113 9.59944 8.80772 9.65331 8.76567 9.72268L7.97912 11.0203C7.88806 11.1705 7.70494 11.2374 7.5385 11.1812L6.11993 10.7024C6.04213 10.6761 5.95787 10.6761 5.88007 10.7024L4.4615 11.1812C4.29506 11.2374 4.11194 11.1705 4.02088 11.0203L3.23433 9.72268C3.19228 9.65331 3.12887 9.59944 3.05361 9.56916L1.65674 9.00722C1.49623 8.94265 1.40073 8.77665 1.42559 8.60543L1.64584 7.08861C1.65731 7.00961 1.64328 6.92901 1.60577 6.85854L0.887583 5.50909C0.806728 5.35716 0.839663 5.16971 0.967461 5.05444L2.09469 4.03776C2.15435 3.98395 2.19515 3.91241 2.21108 3.83367L2.51422 2.33575C2.54882 2.1648 2.69671 2.04027 2.87106 2.03529L4.36788 1.9925C4.44968 1.99017 4.52846 1.96114 4.59222 1.90985L5.76495 0.966435Z"
        fill="#078842"
        stroke="#078842"
        strokeWidth="0.857143"
      />
      <path
        d="M3.375 6.40234L4.5 7.90234L7.875 4.52734"
        stroke="white"
        strokeWidth="0.75"
        strokeLinecap="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_9110_3814">
        <rect
          width="12"
          height="12"
          fill="white"
          transform="translate(0 0.0273438)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default GreenTickSvg;
