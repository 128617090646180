export const SmileySvg = () => (
  <svg
    width="157"
    height="237"
    viewBox="0 0 157 237"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity="0.7">
      <path
        d="M146.336 279.564C226.977 279.564 292.35 217.439 292.35 140.805C292.35 64.1703 226.977 2.04565 146.336 2.04565C65.6952 2.04565 0.322754 64.1703 0.322754 140.805C0.322754 217.439 65.6952 279.564 146.336 279.564Z"
        fill="url(#paint0_linear_8056_39995)"
      />
      <path
        d="M93.7092 86.118C94.5941 82.3167 92.0686 78.5533 88.0685 77.7123C84.0684 76.8714 80.1083 79.2713 79.2234 83.0727L77.9455 88.5625C77.0606 92.3639 79.5859 96.1272 83.586 96.9682C87.5861 97.8091 91.5464 95.4092 92.4313 91.6078L93.7092 86.118Z"
        fill="#EEE8F5"
      />
      <path
        d="M142.062 96.4539C142.947 92.6525 140.422 88.8891 136.422 88.0482C132.421 87.2072 128.461 89.6071 127.576 93.4085L126.299 98.8983C125.414 102.7 127.939 106.463 131.939 107.304C135.939 108.145 139.899 105.745 140.784 101.944L142.062 96.4539Z"
        fill="#EEE8F5"
      />
      <path
        d="M177.677 143.057C185.789 145.011 189.082 154.299 183.838 160.497C156.743 192.524 108.714 202.872 69.0583 182.948C43.1381 169.926 26.4558 146.825 21.5244 121.409C20.006 113.583 27.5656 106.9 35.683 108.855L177.677 143.057Z"
        fill="url(#paint1_linear_8056_39995)"
      />
      <path
        d="M77.7162 125.708L127.858 137.597C130.347 138.187 131.864 140.586 131.243 142.952L130.586 145.455C128.456 153.568 119.795 158.512 111.258 156.488L83.033 149.796C74.4956 147.772 69.2936 139.541 71.4236 131.428L72.0808 128.925C72.7018 126.559 75.227 125.118 77.7162 125.708Z"
        fill="url(#paint2_linear_8056_39995)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M62.5919 -2.26447L56.8591 11.7488C55.998 13.8536 56.0315 16.2183 56.9518 18.2979L62.6758 31.2314C64.0148 34.2571 67.0123 36.2084 70.321 36.2084H90.2122L98.834 46.2311C99.911 47.4831 101.926 47.1788 102.586 45.6647L109.216 30.4414L108.629 30.5369L113.98 18.3166C114.897 16.2243 114.917 13.8483 114.037 11.7406L108.164 -2.32098C106.864 -5.43301 103.822 -7.45935 100.449 -7.45935H70.3299C66.9351 -7.45935 63.8773 -5.40652 62.5919 -2.26447Z"
        fill="white"
      />
      <path
        d="M87.42 20.5696C87.3568 21.1182 86.9217 21.5678 86.3695 21.5678H83.6853C83.133 21.5678 82.6778 21.1189 82.7268 20.5688C82.8065 19.673 83.0012 18.9634 83.3109 18.4399C83.5248 18.0625 83.8349 17.6901 84.2413 17.3227C84.6477 16.9553 85.1717 16.5383 85.8134 16.0715C86.3481 15.6743 86.808 15.2424 87.193 14.7757C87.9416 13.8522 88.3159 12.8989 88.3159 11.9158C88.3159 11.0618 88.0592 10.3965 87.5459 9.91989C87.0433 9.43331 86.3321 9.19003 85.4124 9.19003C84.6424 9.19003 83.9793 9.38863 83.4232 9.78583C83.1268 10.0017 82.9093 10.2763 82.7708 10.6095C82.5609 11.1146 82.1494 11.5732 81.6024 11.5732H78.9836C78.4382 11.5732 77.9835 11.1336 78.0579 10.5933C78.1728 9.75975 78.4587 8.9963 78.9155 8.3708C79.5465 7.51682 80.4181 6.8664 81.5303 6.41955C82.6425 5.96277 83.9312 5.73438 85.3963 5.73438C87.7812 5.73438 89.642 6.28053 90.9788 7.37283C92.3263 8.45521 93.0001 9.92982 93.0001 11.7967C93.0001 12.6606 92.8236 13.4599 92.4707 14.1948C92.1071 14.9296 91.6098 15.6247 90.9788 16.2801C90.3585 16.9355 89.6367 17.5859 88.8132 18.2313C88.4282 18.5292 88.1234 18.8321 87.8988 19.1399C87.6956 19.4378 87.5566 19.7804 87.4817 20.1677C87.4571 20.292 87.4365 20.4259 87.42 20.5696ZM85.4629 23.9212C86.5159 23.9212 87.3695 24.7748 87.3695 25.8278C87.3695 26.8808 86.5159 27.7344 85.4629 27.7344H84.5919C83.5389 27.7344 82.6853 26.8808 82.6853 25.8278C82.6853 24.7748 83.5389 23.9212 84.5919 23.9212H85.4629Z"
        fill="#FDF5FF"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_8056_39995"
        x1="278.458"
        y1="334.197"
        x2="38.1157"
        y2="59.0859"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#E6D4EA" />
        <stop offset="1" stopColor="#F4FBFF" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_8056_39995"
        x1="211.881"
        y1="168.873"
        x2="7.81021"
        y2="52.2557"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#E6D4EA" />
        <stop offset="1" stopColor="#F4FBFF" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_8056_39995"
        x1="184.632"
        y1="236.735"
        x2="177.111"
        y2="108.026"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#E6D4EA" />
        <stop offset="1" stopColor="#F4FBFF" />
      </linearGradient>
    </defs>
  </svg>
);
