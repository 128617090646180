const QnaCharacter = () => (
  <svg
    width="106"
    height="155"
    viewBox="0 0 106 155"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M49.0703 83.1494L75.4896 66.9336C78.1399 65.3067 78.9442 61.8798 77.2871 59.279C75.6308 56.677 72.1362 55.8888 69.4885 57.5145L56.0016 65.7924L53.1287 55.394C52.3107 52.4327 49.1995 50.6832 46.1853 51.4868C43.1686 52.2896 41.3865 55.341 42.2045 58.3014L49.0703 83.1494Z"
      fill="#F0EEF9"
    />
    <path
      d="M34.851 169.068C4.55754 169.068 -20.0001 144.966 -20.0001 115.233C-20.0001 85.5012 4.55754 61.3984 34.851 61.3984C65.1445 61.3984 89.7021 85.5012 89.7021 115.233C89.7021 144.966 65.1445 169.068 34.851 169.068Z"
      fill="url(#paint0_linear_8000_29733)"
    />
    <path
      d="M54.6167 136.278C61.6434 136.278 66.5656 134.984 67.0506 134.851C68.3449 134.496 69.1028 133.179 68.7414 131.907C68.3801 130.638 67.0363 129.897 65.7449 130.247C64.6409 130.547 39.0552 137.164 20.9517 116.793C20.0673 115.798 18.5295 115.696 17.5168 116.563C16.504 117.43 16.3984 118.939 17.2828 119.934C29.303 133.459 44.2493 136.278 54.6167 136.278Z"
      fill="#ECE6F1"
    />
    <path
      d="M30.9651 69.8842C43.8012 69.8842 54.2447 80.1342 54.2447 92.733C54.2447 93.8966 54.1543 95.0398 53.9826 96.1571L55.9044 96.6146C59.519 88.5877 67.7039 82.9697 77.2048 82.9697C90.0409 82.9697 100.484 93.2192 100.484 105.818C100.484 118.417 90.0409 128.667 77.2048 128.667C64.3687 128.667 53.9252 118.417 53.9252 105.818C53.9252 104.228 54.0925 102.675 54.4092 101.176L52.7493 100.781C49.4251 109.42 40.916 115.581 30.9651 115.581C18.129 115.581 7.68547 105.332 7.68547 92.733C7.68547 80.1342 18.129 69.8842 30.9651 69.8842ZM77.2048 123.888C87.3563 123.888 95.6154 115.782 95.6154 105.818C95.6154 95.8545 87.3563 87.7484 77.2048 87.7484C67.0532 87.7484 58.7941 95.8545 58.7941 105.818C58.7941 115.782 67.0532 123.888 77.2048 123.888ZM30.9651 110.803C41.1166 110.803 49.3757 102.696 49.3757 92.733C49.3757 82.769 41.1166 74.6629 30.9651 74.6629C20.8135 74.6629 12.5544 82.769 12.5544 92.733C12.5544 102.696 20.8135 110.803 30.9651 110.803Z"
      fill="#EEE8F3"
    />
    <path
      d="M68.381 109.926C66.7717 109.926 65.4672 108.646 65.4672 107.066C65.4672 105.487 66.7717 104.207 68.381 104.207C69.9904 104.207 71.2949 105.487 71.2949 107.066C71.2949 108.646 69.9904 109.926 68.381 109.926Z"
      fill="#EEE8F3"
    />
    <path
      d="M38.5104 102.692C36.9011 102.692 35.5964 101.411 35.5964 99.832C35.5964 98.2525 36.9011 96.972 38.5104 96.972C40.1198 96.972 41.4243 98.2525 41.4243 99.832C41.4243 101.411 40.1198 102.692 38.5104 102.692Z"
      fill="#EEE8F3"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M67.245 0.254883H89.4442C90.9491 0.254883 91.7015 0.254883 92.4125 0.419625C93.043 0.56571 93.6476 0.806829 94.2056 1.13471C94.8348 1.50447 95.3807 2.02225 96.4726 3.05782L102.552 8.82363L102.552 8.82368L102.552 8.82376C103.726 9.93725 104.313 10.494 104.733 11.1524C105.106 11.7361 105.381 12.3765 105.548 13.0485C105.737 13.8067 105.737 14.6158 105.737 16.2339V26.8203C105.737 28.4253 105.737 29.2278 105.551 29.9806C105.387 30.6478 105.115 31.2842 104.748 31.865C104.333 32.5202 103.755 33.076 102.597 34.1875L102.597 34.1875L102.597 34.1876L96.8575 39.6979L96.8574 39.698C95.7626 40.7491 95.2151 41.2747 94.5824 41.6501C94.0213 41.9831 93.4126 42.228 92.7773 42.3764C92.0608 42.5438 91.3019 42.5438 89.7841 42.5438H67.1887C65.6986 42.5438 64.9536 42.5438 64.2488 42.3821C63.6238 42.2386 63.024 42.0018 62.4696 41.6796C61.8443 41.3163 61.3002 40.8074 60.212 39.7895L54.2365 34.2003C53.0437 33.0846 52.4474 32.5268 52.0201 31.8648C51.6413 31.278 51.3614 30.633 51.1916 29.9555C51 29.1912 51 28.3746 51 26.7414V16.2184C51 14.6058 51 13.7994 51.1872 13.0436C51.3532 12.3735 51.6267 11.7348 51.9972 11.1524C52.4151 10.4953 52.9987 9.93894 54.1659 8.82617L60.1976 3.07588L60.1976 3.07588C61.2908 2.03375 61.8373 1.51268 62.468 1.14053C63.0273 0.81053 63.6337 0.567805 64.2662 0.420737C64.9795 0.254883 65.7347 0.254883 67.245 0.254883ZM62.9016 42.5556L62.9016 53.1866C62.9016 54.8037 64.8206 55.6528 66.0173 54.5653L79.2331 42.5556H62.9016Z"
      fill="white"
    />
    <circle
      cx="4.15115"
      cy="4.15115"
      r="4.15115"
      transform="matrix(-1 0 0 1 96.9058 21.2983)"
      fill="#FFF5FE"
    />
    <circle
      cx="4.15115"
      cy="4.15115"
      r="4.15115"
      transform="matrix(-1 0 0 1 82.9795 21.2985)"
      fill="#FFF5FE"
    />
    <circle
      cx="4.15115"
      cy="4.15115"
      r="4.15115"
      transform="matrix(-1 0 0 1 68.4009 21.2985)"
      fill="#FFF5FE"
    />
    <defs>
      <linearGradient
        id="paint0_linear_8000_29733"
        x1="22.5"
        y1="243.66"
        x2="78.8635"
        y2="87.5826"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#E6D4EA" />
        <stop offset="1" stopColor="#F4FBFF" />
      </linearGradient>
    </defs>
  </svg>
);

export default QnaCharacter;
